<template>
    <footer>
        <div class="custom-shape-divider-top-1660311304">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
            </svg>
        </div>
        <v-container
        class="text-center"
        >
           <h1 class="footer-title">{{ $t('get-in-touch') }}</h1>
           <h2 class="footer-subtitle">{{ $t('lets-hear') }}</h2>
           <v-btn to="/contact" dark>{{ $t('contact-us') }}</v-btn>
           <Divider :color="'#555'" :height="4"></Divider>
           <div class="footer--info-container">
                <section class="footer--info">
                    <h1 class="footer--info-title">{{ $t('about-us') }}</h1>
                    <img src="/assets/logos/WebxIO_Logo_normal_White_nospace.png" alt="logo of webxio" width="120px" height="30px" class="mb-2" />
                    <p class="footer--info-text">{{ $t('subtitle') }}</p>
                </section>
                <section class="footer--info">
                    <h1 class="footer--info-title">{{ $t('quick-links') }}</h1>
                    <ul class="footer--info-list">
                        <li class="footer--info-item" v-for="item in items" :key="item.text">
                            <router-link :to="item.to" exact>{{ $t(item.text) }}</router-link>
                        </li>
                    </ul>
                </section>
                <section class="footer--info">
                    <h1 class="footer--info-title">{{ $t('info') }}</h1>
                    <ul class="footer--info-list">
                        <li class="footer--info-item">
                            <router-link to="/agb" exact>{{ $t('agb') }}</router-link>
                        </li>
                        <li class="footer--info-item">
                            <router-link to="/privacy" exact>{{ $t('privacy') }}</router-link>
                        </li>
                        <li class="footer--info-item">
                            <router-link to="/impressum" exact>{{ $t('impressum') }}</router-link>
                        </li>
                    </ul>
                </section>
                <section class="footer--info">
                    <h1 class="footer--info-title">Social</h1>
                    <ul class="footer--info-list">
                        <li class="footer--info-item">
                            <a href="https://www.github.com/webxio" class="d-flex align-center" target="__blank"><v-icon class="mr-1">mdi-github</v-icon> Github</a>
                        </li>
                    </ul>
                    <ul class="footer--info-list">
                        <li class="footer--info-item">
                            <button class="d-flex align-center" @click="onShare"><v-icon class="mr-1">mdi-share</v-icon>{{ $t('share') }}</button>
                        </li>
                    </ul>
                </section>
           </div>
           <div class="copyright mt-4 mb-0">
                <p>&copy; {{new Date().getFullYear()}} WebXIO</p>
           </div>
        </v-container>
    </footer>
</template>

<style scoped>
footer {
    background: #050505;
    padding: 1rem;
    color: white;
    position: relative;
}
.footer-title {
    font-size: 60px;
    margin-bottom: 1rem;
}
.footer-subtitle {
    font-size: 16px;
    margin-bottom: 2rem;
}
.footer--info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    max-width: 240px;
}
.footer--info-title {
    font-size: 24px;
    margin-bottom: 1rem;
}
.footer--info-container {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    margin-top: 5rem;
}
ul.footer--info-list {
    margin: 0;
    text-align: left;
    padding: 0;
    list-style: none;
}
.footer--info-item > * {
    color: #666eff !important;
    text-decoration: none;
    padding: .75rem 0;
    display: block;
    margin: auto;
}
.custom-shape-divider-top-1660311304 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1660311304 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 157px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1660311304 .shape-fill {
    fill: #121212;
}

div.container {
    margin-top: 10rem;
}
@media screen and (max-width: 768px) {
    .footer--info-container {
        flex-direction: column;
        gap: 2rem;
    }
    .footer--info {
        max-width: 100%;
    }
}
</style>

<script>
import Divider from "../shared/Divider.vue";

export default {
    name: "FooterVue",
    data: () => ({
    //
    }),
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        onShare () {
            navigator.share({
                title: "WebXIO",
                url: "https://webxio.at",
            }).then(() => {
                this.$toast.success("Thanks for sharing!");
            })
        }
    },
    components: { Divider }
};
</script>