<template>
    <div class="webxio-divider" :style="style"></div>
</template>

<style>
.webxio-divider {
    width: 100%;
}
</style>

<script>
export default {
    name: 'DividerVue',
    props: {
        color: {
            type: String,
            default: '#e0e0e0'
        },
        height: {
            type: Number,
            default: 1
        },
        marginTop: {
            type: Number,
            default: 1
        },
        marginBottom: {
            type: Number,
            default: 1
        }
    },
    computed: {
        style() {
            return {
                'background-color': this.color,
                'height': `${this.height}px`,
                'margin-top': `${this.marginTop}rem`,
                'margin-bottom': `${this.marginBottom}rem`,
            }
        }
    }
}
</script>