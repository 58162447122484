import Vue from "vue";
import VueRouter from "vue-router";

const LandingPage = () => import("../components/routes/LandingPage");
const ContactPage = () => import("../components/routes/ContactPage");
const AboutUsPage = () => import("../components/routes/AboutUsPage");
const AdminPage = () => import("../components/routes/AdminPage");
const ProjectsPage = () => import("../components/routes/ProjectsPage");
const ProjectView = () => import("../components/routes/ProjectView");
const MessagesView = () => import("../components/routes/MessagesView");
const MessageView = () => import("../components/routes/MessageView");
const StatusPage = () => import("../components/routes/StatusPage");
const AGBPage = () => import("../components/routes/AGBPage");
const ImpressumPage = () => import("../components/routes/ImpressumPage");
const Error_404 = () => import("../components/routes/Error404");
const PrivacyPolicy = () => import("../components/routes/DataSafety");

import protectedRoute from "@/utils/protectedRoute";
import axios from "axios";

Vue.use(VueRouter);

const routes = [
    {path: '/', name:'LandingPage', component: LandingPage},
    {path: '/about', name:'About', component: AboutUsPage},
    {path: '/projects', name:'Projects', component: ProjectsPage},
    {path: '/project/:name', name:'ProjectView', component: ProjectView},
    {path: '/contact', name:'Contact', component: ContactPage},
    {path: '/admin', name:'Admin', component: AdminPage},
    {path: '/agb', name:'AGB', component: AGBPage},
    {path: '/impressum', name:'Impressum', component: ImpressumPage},
    {path: '/privacy', name:'Privacy Policy', component: PrivacyPolicy},
    {path: '/messages', name:'Messages', component: MessagesView, beforeEnter: protectedRoute},
    {path: '/message/:id', name:'Message', component: MessageView, beforeEnter: protectedRoute},
    {path: '/status', name:'Statistics', component: StatusPage, beforeEnter: protectedRoute},
    {path: '/error404', name:'Error_404', component: Error_404},
    {path: '*', redirect: '/error404', component: Error_404}
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.HOSTNAME,
    routes: routes
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    axios.post("/api/statistics/pageView?url=" + to.path);
    next();
});

export default router;