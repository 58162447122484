import axios from "axios";

const state = {
  user: null,
  token: null,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  StateUser: (state) => state.user,
};

const actions = {
  async LogIn({ commit }, user) {
    try {

      const response = await axios.post("/api/authentication/login", {
        user: user,
        userToken: user.userToken,
      });
      await commit("setUser", response.data);
      await commit("setToken", response.data.token);
    } catch {
      await commit("setUser", null);
      await commit("setToken", null);
    }
  },

  async CheckToken({ commit }) {
    try {
      const response = await axios.post("/api/authentication/check_token");
      
      await commit("setUser", response.data);
      await commit("setToken", response.data.token);
    } catch {
      await commit("setUser", null);
      await commit("setToken", null);
    }
  },

  async LogOut({ commit }) {
    await commit("logout", null);
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.token = token;
  },
  logout(state, user) {
    state.user = user;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
