import Vuex from "vuex";
import Vue from "vue";
import auth from "./modules/auth";
import consent from "./modules/consent";

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    auth,
    consent
  },
  plugins: [],
});