<template>
    <nav>
        <div class="nav-container" v-click-outside="onClickOutside">
            <div class="nav-badge">
                <button @click="$router.push({name: 'LandingPage'}).catch(err => {})"><img src="/assets/logos/WebxIO_Logo_normal_White_nospace.png" alt="logo of webxio" width="200px" height="50px" /></button>
            </div>
            <button class="ml-auto toggle-btn" @click="toggleState()">
                <v-app-bar-nav-icon aria-label="expand navbar"></v-app-bar-nav-icon>
            </button>
            <ul class="nav-items" :class="{'collapsed': collapsed}">
                <li class="nav-item" v-for="item in items" :key="item.to">
                    <router-link :to="item.to" class="nav-link" :active-class="'active'" exact>{{ $t(item.text) }}</router-link>
                </li>
            </ul>
            <div class="ml-auto mr-2">
                <select
                v-model="$i18n.locale"
                class="language-selector"
                >
                    <option v-for="local in locals" :key="local" :value="local">{{local}}</option>
                </select>
            </div>
        </div>
    </nav>
</template>

<style>
nav {
    box-shadow: 0 4px 3px rgba(0, 0, 0, 0.3);
    background: var(--nav-bg);
}

.nav-badge {
    margin-right: 1rem;
}

.nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.nav-container > * {
    padding: .5rem;
}

ul.nav-items {
    display: flex;
    gap: 1rem;
    list-style: none;
    margin: 0;
    padding: 0 !important;
    transition: height .3s ease-in-out;
}

.nav-item {
    margin: 0;
    padding: 0;
    display: block;
}

a.nav-link {
    display: block;
    font-size: 16px;
    text-decoration: none;
    background-color: #3d3d3d;
    padding: .5rem .75rem;
    border-radius: .25rem;
    transition: background-color .2s ease-in-out;
}

a.nav-link:hover {
    background-color: #505050;
    color: #000;
}

a.nav-link.active {
    background: var(--logo-gradient);
    color: #fff !important;
}

.toggle-btn {
    display: none;
    position: absolute;
    top: 10px;
    right: 0;
}

select.language-selector {
    outline: none;
    color: white !important;
    border: none;
    cursor: pointer;
    position: relative;
}

.language-selector:focus::-ms-value {
  background-color: transparent;
}

.language-selector > option {
    color: black;
    border: none;
    outline: none;
    box-shadow: none;
}

@media only screen and (max-width: 768px) {
    .nav-container {
        flex-direction: column;
        align-items: flex-start;
    }
    .nav-badge {
        margin-right: 0;
    }
    ul.nav-items:not(.collapsed) {
        display: none;
    }
    ul.nav-items.collapsed {
        flex-direction: column;
        width: 100%;
        gap: 0;
        margin-bottom: 1rem;
    }
    a.nav-link {
        text-align: center;
        border-radius: 0px;
    }
    .toggle-btn {
        display: flex;
    }
}
</style>

<script>
export default {
    name: "NavBar",
    data() {
        return {
            collapsed: false,
            locals: ["en", "de"]
        }
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    created() {
        if(this.$i18n.locale.includes("en")) {
            this.$i18n.locale = "en";
        }
    },
    beforeMount() {
        window.addEventListener("resize", this.onResize);
        window.innerWidth < 768 ? this.compact = true : this.compact = false;
    },
    methods: {
        toggleState() {
            this.collapsed = !this.collapsed;
        },
        onClickOutside () {
            if(this.collapsed === true) {
                this.collapsed = false;
            }
        },
        onResize() {
            window.innerWidth < 768 ? this.compact = true : this.compact = false;
        }
    }
}
</script>