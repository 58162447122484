import './styles.css';
import Vue from 'vue'
import App from './App.vue'

import vuetify from './plugins/vuetify'
import router from './plugins/router';

import axios from "axios";
import store from "./store/store";

import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueCookies from 'vue-cookies';
import i18n from './i18n'
import VueToast from "vue-toast-notification";

Vue.use(VueReCaptcha, { siteKey: '6Ldo9q0hAAAAAObnzZj0rfP0v6L8vepYsDq4H_CR' })
Vue.use(VueCookies);
Vue.use(VueToast, {
  position: 'top-right',
  duration: 2000
});

axios.defaults.withCredentials = true;
axios.interceptors.response.use(undefined, function(error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("LogOut");
      return;
    }
  }
});

store.dispatch("CheckToken").then(() => {
  new Vue({
    store,
    vuetify,
    router,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})

