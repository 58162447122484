import VueCookies from "vue-cookies";

const state = {
    consent: VueCookies.get("checkCookie") ?? undefined,
};

const getters = {
  consentGiven: (state) => {
    if (state.consent === undefined || state.consent === "false") return false;
    return true;
  },
};

const actions = {
    async ConsentInput({commit}, consent) {
        try {
            await commit("setConsent", consent);
        } catch {
            await commit("setConsent", false);
        }
    },
    async Revoce({ commit }) {
        await commit("setConsent", false);
    },
};

const mutations = {
    setConsent(state, consent) {
        state.consent = consent;
        VueCookies.set("checkCookie", consent.consent, "2y");
    },
};

export default {
  state,
  getters,
  actions,
  mutations,
};