<template>
  <v-app>
    <Navbar :items="items"></Navbar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer :items="items"></Footer>
  </v-app>
</template>

<script>
import Navbar from './components/layout/Navbar.vue';
import Footer from './components/layout/Footer.vue';
export default {
  name: 'App',
  components: {
    Navbar,
    Footer
},
  data: () => ({
    items: [
      {
          text: "home",
          to: "/"
      },
      {
          text: "projects",
          to: "/projects"
      },
      {
          text: "about-us",
          to: "/about"
      },
      {
          text: "contact-us",
          to: "/contact"
      },
    ]
  }),
};
</script>
